import React from 'react';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Skeleton from '@mui/material/Skeleton';
// import Paper from '@mui/material/Paper';
import { Card as CardMui, CardHeader, CardContent, CardMedia, CardActionArea, CardActions } from '@mui/material';
// import { Avatar } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@mui/material/Tooltip';

import { Avatar } from './Display'

const Header = ({ avatar, avatarColor, title, subtitle }) => {
	const avatarItem = avatar ? (
		<Avatar color={avatarColor}>{avatar}</Avatar>
	) : null
	// const action = (
	// 	<IconButton aria-label="settings">
	// 		<MoreVertIcon />
	// 	</IconButton>
	// )
	const titleDraw = <Typography variant="subtitle">{title}</Typography>
	return (
		<CardHeader
			avatar={avatarItem}
			action={false}
			title={titleDraw}
			subheader={subtitle}
		/>
	)
}

function Content({ image, children }) {
	const content = []
	image && content.push(
		<CardMedia
			key="_cardMedia"
			component="img"
			height="140"
			image={image}
		/>
	)
	children && content.push(
		<CardContent key="_cardContent">
			{children}
		</CardContent>
	)
	return content.length ? content : null
}

function Action({ icon, label, linkTo, onClick }) {
	const btn = <FontAwesomeIcon icon={icon} size="sm" />
	const btnParams = onClick ?
		{ onClick } :
		{ component:Link, to:linkTo }
	const content = <IconButton size="" {...btnParams}>{btn}</IconButton>
	return label ? <Tooltip title={label} placement="bottom">{content}</Tooltip> : content
}

function Actions({ def }) {
	return (
		<CardActions sx={{ pt:0 }}>
			<Grid container justifyContent="flex-end">
				{ def.map((action, itemIdx) => (
					<Action key={itemIdx} {...action} />
				))}
			</Grid>
		</CardActions>
	)
}

function ContentAction({ linkTo, onClick, children }) {
	const actionParams = onClick ?
		{ onClick } :
		{ component:Link, to:linkTo }
	return <CardActionArea {...actionParams}>{children}</CardActionArea>
}

// export function Card({ linkTo, onClick, title, description, image="/images/missing.jpg", actions, sx={} }) {
export function Card({ avatar, avatarColor, title, subtitle, children, image, linkTo, onClick, actions, sx={} }) {
	const content = <Content image={image}>{children}</Content>
	return (
		<Grid item xs={12} md={6} lg={4} xl={3} sx={sx}>
			<CardMui sx={{ m:'auto', maxWidth:325 }}>
				{ Boolean(avatar || title) && <Header avatar={avatar} avatarColor={avatarColor} title={title} subtitle={subtitle} /> }
				{ Boolean(linkTo || onClick) ? <ContentAction linkTo={linkTo} onClick={onClick}>{content}</ContentAction> : content }
				{ Boolean(actions) && <Actions def={actions} /> }
			</CardMui>
		</Grid>
	)
}

export default function Cards({ title, children, sx={} }) {
	return (
		<Box sx={{ width: '100%', ...sx }}>
			<Typography gutterBottom variant="h5" component="div">
				{title}
			</Typography>
			<Grid
				container
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 2, md: 3 }}
  				justifyContent="space-evenly"
  				alignItems="center"
			>
				{children}
			</Grid>
		</Box>
	)
}

// export default function Cards({ def, defBtns, ids, getRow }) {
	// const navigate = useNavigate()
	// const query = useQuery()
	// // const [ page, setPage ] = useState(useQueryPage)
	// const [ pageIds, setPageIds ] = useState([])
	// const [ pageSize, setPageSize ] = useState(pageSizeDefault)
	// const page = useQueryPage()

	// // const handleChPageNum = (e, newPage) => setPage(newPage)
	// const handleChPageNum = (e, newPage) => {
	// 	query.set('page', newPage)
	// 	navigate('?'+query.toString())
	// }
	// const handleChPageSize = e => setPageSize(parseInt(e.target.value))
	// const refreshPage = () => setPageIds(pageSize ? ids.slice(page*pageSize, (page+1)*pageSize) : ids)

	// useEffect(refreshPage, [ ids, page, pageSize ])

    // return (
	// 	<>
    //     <TableContainer component={Paper}>
	// 		<TableMui sx={{ minWidth: 650 }}>
	// 			<Head def={def} btnsActive={Boolean(defBtns)} />
	// 			<TableBody>
	// 				{ pageIds.map(id => (
	// 					<BodyRow
	// 						key={id}
	// 						def={def}
	// 						defBtns={defBtns}
	// 						id={id}
	// 						getRow={getRow}
	// 					/>
	// 				))}
	// 			</TableBody>
    //   		</TableMui>
	// 	</TableContainer>
	// 	{ pageSizeDefault && (
	// 		<TablePagination
	// 			rowsPerPageOptions={pageSizeOpts || pageSize}
	// 			component="div"
	// 			count={ids.length}
	// 			rowsPerPage={pageSize}
	// 			page={page}
	// 			onPageChange={handleChPageNum}
	// 			onRowsPerPageChange={handleChPageSize}
	// 		/>
	// 	)}
	// 	</>
    // )
// }