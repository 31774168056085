import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Skeleton from '@mui/material/Skeleton';
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'

import BtnPad from './BtnPad'
import Button from './Button'
import { DisplayUser } from './Display'

const Head = ({ def, btnsActive }) => (
	<TableHead>
		<TableRow>
			{ def.map((defCol, colIdx) => (
				<TableCell
					key={colIdx}
					sx={{ fontWeight:'bold', textAlign:evalCellAlignment(defCol) }}
				>
					{defCol.label || ' '}
				</TableCell>
			))}
			{ btnsActive && <TableCell>&nbsp;</TableCell> }
		</TableRow>
	</TableHead>
)

const gfxCellAlignment = {
	datetime: 'center',
	date: 'center',
	money: 'right',
	number: 'right',
	bool: 'center',
	boolean: 'center',
	icon: 'center',
}
const evalCellAlignment = def => def?.cellAlign || (def?.type && gfxCellAlignment.hasOwnProperty(def.type) ? gfxCellAlignment[def.type] : 'left')

const calcValue = (def, row) => {
	let value = def?.field ? row[def.field] : (typeof(def?.content)==='function' ? def.content(row) : def.content)

	switch(def?.type) {
		case 'datetime':
			if(value) {
				let dateObj = new Date(value)
				value = ("0" + dateObj.getDate()).slice(-2)
				value += '/' + ("0" + (dateObj.getMonth() + 1)).slice(-2)
				value += '/' + dateObj.getFullYear()
				value += ' ' + ("0" + dateObj.getHours()).slice(-2)
				value += ':' + ("0" + dateObj.getMinutes()).slice(-2)
				value += ':' + ("0" + dateObj.getSeconds()).slice(-2)
			}
		break
		case 'date':
			if(value) {
				let dateObj = new Date(value)
				value = ("0" + dateObj.getDate()).slice(-2)
				value += '/' + ("0" + (dateObj.getMonth() + 1)).slice(-2)
				value += '/' + dateObj.getFullYear()
			}
		break
		case 'money':
			if(value) {
				value = (value/10000)
					.toFixed(2)
					.split('.')
				let valueInt = value[0]
					.split('')
					.reduceRight((acc, cur, idx, arr) =>
						cur + ((arr.length-idx)%3===1 ? '.' : '') + acc)
				value = '€ ' + valueInt + ',' + value[1]
			}
			else
				value = '-'
		break
		case 'number':
			value = value ? (value/10000).toFixed(2).replace(/\./g, ",") : '-'
		break
		case 'bool':
		case 'boolean':
			const trueIcon = def.hasOwnProperty('trueIcon') ? def.trueIcon : faCheck
			const falseIcon = def.hasOwnProperty('falseIcon') ? def.falseIcon : faTimes
			const valueIcon = value ? trueIcon : falseIcon
			value = valueIcon ? <FontAwesomeIcon icon={valueIcon} size="lg" /> : ''
		break;
		case 'multiline':
			value = value ? value.split('\n').map((row, rowIdx) =>
				(<Box key={rowIdx}>{row}</Box>)
			) : ' '
		break
		case 'icon':
			value = Boolean(value) && <FontAwesomeIcon icon={value} size="lg" />
		break;
		case 'user':
			value = <DisplayUser uid={value} />
		break
		case 'color':
			value = (
				<Box sx={{
					bgcolor: value,
					border: '1px solid black',
					width: 100,
					textAlign: 'center',
				}}>
					{value || '-'}
				</Box>
			)
		break;
	}
	return value || ' '
}

const BodyCell = ({ def, data }) => {
	const textAlign = evalCellAlignment(def)
	return (
		<TableCell sx={{ p:1, textAlign }}>
			{ data===null ? <Skeleton variant="text" /> : calcValue(def, data) }
		</TableCell>
	)
}

function BodyRow({ def, defBtns, id, getRow }) {
	const [ data, setData ] = useState(null)

	const handleRefresh = () => { getRow(id).then(setData) }
	useEffect(handleRefresh, [ getRow, id ])

	return (
		<TableRow 
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			{ def.map((defCol, colIdx) => <BodyCell key={colIdx} def={defCol} data={data} /> ) }
			{ data!==null && defBtns && <Btns data={data} def={defBtns} /> }
		</TableRow>
	)
}

function Btns({ def, data }) {
	//const defCalc = def.map(defItem => typeof(defItem)==='function' ? defItem(data) : defItem)
	const defCalc = def.map(defItem => {
		let tmpBtn = {}
		for(const [key, value] of Object.entries(defItem)) {
			if(key==='onClick')
				tmpBtn[key] = () => value(data)
			else
				tmpBtn[key] = typeof(value)==='function' ? value(data) : value
		}
		return tmpBtn
	})

	return (
		<TableCell>
			<BtnPad def={defCalc} />
		</TableCell>
	)
}

function useQuery() {
	const { search } = useLocation()
	return React.useMemo(() => new URLSearchParams(search), [search])
}
const useQueryPage = () => {
	const query = useQuery()
	return React.useMemo(() => parseInt(query.get('page')) || 0, [query])
}

const mkDT = () => new Date()
	.toISOString()
	.slice(0, 16)
	.replace(/-/g, '')
	.replace(/:/g, '')
	.replace(/T/, '-')

export const TableActions = ({ mkCsvData, csvName, actions }) => {
	const buildData = async () => ({
		content: await mkCsvData(),
		type: 'text/csv',
		download: csvName ? csvName + '-' + mkDT() + '.csv' : 'export.csv',
	})
	
	return (
		<Box display="flex" justifyContent="right">
			{ mkCsvData && <Button mkData={buildData} icon={faFileCsv} label="Scarica CSV" /> }
			{ Array.isArray(actions) && actions.map((action, actionIdx) => (
				<Button key={actionIdx} {...action} />
			))}
		</Box>
	)
}

export default function Table({ def, csvDef, csvName, defBtns, ids, getRow, pageSize:pageSizeDefault, pageSizeOpts, tableActions }) {
	const navigate = useNavigate()
	const query = useQuery()
	const [ pageIds, setPageIds ] = useState([])
	const [ pageSize, setPageSize ] = useState(pageSizeDefault)
	const page = useQueryPage()

	const handleChPageNum = (e, newPage) => {
		query.set('page', newPage)
		navigate('?'+query.toString())
	}
	const handleChPageSize = e => setPageSize(parseInt(e.target.value))
	const refreshPage = () => {
		if(page*pageSize >= ids.length && page>0) {
			query.set('page', 0)
			navigate('?'+query.toString())
		}
		else
			setPageIds(pageSize ? ids.slice(page*pageSize, (page+1)*pageSize) : ids)
	}

	const mkCsvData = async () =>
		[ csvDef.map(colDef => colDef.label) ].concat(
			await Promise.all(
				ids.map(async _id => {
					const dbRow = await getRow(_id)
					return Promise.all(
						csvDef.map(colDef => calcValue(colDef, dbRow))
					)
				})
		))

	useEffect(refreshPage, [ ids, page, pageSize ])

    return (
		<>
			{ (Boolean(csvDef) || Boolean(tableActions?.length)) && <TableActions mkCsvData={Boolean(csvDef) && mkCsvData} csvName={csvName} actions={tableActions} /> }
			<TableContainer component={Paper}>
				<TableMui sx={{ minWidth: 650 }}>
					<Head def={def} btnsActive={Boolean(defBtns)} />
					<TableBody>
						{ pageIds.map(id => (
							<BodyRow
								key={id}
								def={def}
								defBtns={defBtns}
								id={id}
								getRow={getRow}
							/>
						))}
					</TableBody>
				</TableMui>
			</TableContainer>
			{ pageSizeDefault && (
				<TablePagination
					rowsPerPageOptions={pageSizeOpts || [pageSize]}
					component="div"
					count={ids.length}
					rowsPerPage={pageSize}
					page={page}
					onPageChange={handleChPageNum}
					onRowsPerPageChange={handleChPageSize}
					sx={{ minHeight:'52px', height:'52px', overflow:'hidden' }}
				/>
			)}
		</>
    )
}